<template>
	<div class="timeline-progression-view">
		<div class="swiper-slide" v-for="(item, i) in items" :key="i">
			<div class="timestamp">
				<span class="date"></span>
			</div>
			<div :class="`status ${i > 0 && items[i-1].initiateTimeline ? 'not-first' : ''}`">
				<span>{{ item.name }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['groups'],
	data() {
		return {
			items: []
		};
	},
	created() {
		this.$bus.$on('updateGroups', ($groups) => {
			let newGroups = [];
			if ($groups && Array.isArray($groups) && $groups.length > 0) {
				for (let i in $groups) {
					if ($groups[i].tasks && $groups[i].tasks[0] != null) {
						if ($groups[i].tasks[0].name != null) {
							newGroups.push({
								name: $groups[i].tasks[0].name,
                				initiateTimeline: $groups[i].tasks[0].initiateTimeline,
							});
						}
					}
				}
			}
			this.items = newGroups;
		});
	},
	mounted() {
		if (this.groups != null && this.groups.length > 0) {
			for (let i in this.groups) {
				if (this.groups[i].tasks && this.groups[i].tasks[0] != null) {
					if (this.groups[i].tasks[0].name != null) {
						this.items.push({
							name: this.groups[i].tasks[0].name,
              initiateTimeline: this.groups[i].tasks[0].initiateTimeline || this.groups[i - 1]?.tasks[0].initiateTimeline,
						});
					}
					if (this.groups[i].tasks[0].task_name != null) {
						this.items.push({
							name: this.groups[i].tasks[0].task_name,
              initiateTimeline: this.groups[i].tasks[0].initiateTimeline || this.groups[i - 1]?.tasks[0].initiateTimeline,
						});
					}
				}
			}
		}
	},
	async beforeDestroy() {
		this.$bus.$off('updateGroups');
	}
};
</script>
