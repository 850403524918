<template>
  <b-modal
    title="Task Edit"
    v-model="showEditModal"
    @hidden="onClose"
    size="xl"
  >
    <div class="text-center m-8" v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <template v-else>
      <b-modal
        v-b-modal.showAlert
        id="showAlert"
        variant="light"
        dismissible
        class="w-50 mx-auto"
      >
        <p>You haven't saved your widgets! Do you wish to continue?</p>
        <div slot="modal-footer">
          <button
            class="btn-sm btn-outline-dark mx-2"
            @click="
              () => {
                saveWidgets();
                $bvModal.hide('showAlert');
              }
            "
            v-b-modal.showAlert
          >
            Save widgets
          </button>
          <button
            class="btn-sm btn-outline-danger mx-2"
            @click="
              () => {
                saved = true;
                returnToTasks();
                $bvModal.hide('showAlert');
              }
            "
          >
            Continue Anyway
          </button>
        </div>
      </b-modal>
      <b-modal
        v-b-modal.showRemovalAlert
        id="showRemovalAlert"
        variant="light"
        dismissible
        class="w-100 mx-auto"
      >
        <p>
          Are you sure you want to remove this widget? All data for this widget
          will be lost.
        </p>
        <div slot="modal-footer">
          <button
            class="btn-sm btn-outline-dark mx-2"
            @click="
              () => {
                saved = false;
                removeWidget(widgetIdx);
                $bvModal.hide('showRemovalAlert');
              }
            "
          >
            Yes, I'm sure.
          </button>
          <button
            class="btn-sm btn-outline-danger mx-2"
            @click="
              () => {
                saved = true;
                $bvModal.hide('showRemovalAlert');
              }
            "
          >
            Cancel
          </button>
        </div>
      </b-modal>
      <div>
        <WidgetToolbar />

        <b-card class="task-container" v-if="task != null">
          <h3>{{ task.name }}</h3>

          <draggable
            class="draggable-container"
            v-model="widgets"
            group="widgets"
            @change="(e) => onDrop(e)"
            handle=".handle"
          >
            <div
              id="draggable-element"
              v-for="(widget, i) in widgets"
              :key="i"
              class="row d-flex w-100"
              :style="i == widgetIdx ? 'background-color:#F5F5F5;' : ''"
            >
              <div
                class="py-2 pl-2 text-ellipsis w-90 text-left"
                @click="setWidgetComponent(widgets[i].widget.type, i)"
              >
                <span class="handle">
                  <i class="fas fa-grip-vertical mr-2"></i>
                  <i
                    style="width: 18px;"
                    :class="getWidgetIcons[widgets[i].widget.type]"
                  ></i>
                </span>
                |
                {{ widgets[i].widget.title }}
              </div>
              <div class="dropdown">
                <button
                  :style="i == widgetIdx ? 'background-color:#F5F5F5;' : ''"
                  class="task-dropdown-btn btn btn-secondary"
                  type="button"
                  :id="'editWidgetDropdown' + i"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  @click.stop.prevent
                >
                  <a>&#x22EE;</a>
                </button>
                <ul
                  class="dropdown-menu"
                  :aria-labelledby="'editWidgetDropdown' + i"
                >
                  <li>
                    <a
                      class="dropdown-item"
                      @click="setWidgetComponent(widgets[i].widget.type, i)"
                      ><i class="far fa-edit"></i> Edit widget</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      @click="widgetIdx = i"
                      v-b-modal.showRemovalAlert
                      ><i class="fas fa-trash"></i> Remove widget</a
                    >
                  </li>
                </ul>
              </div>
              <div
                class="row edit-widget-container mx-auto w-100"
                v-if="widgetIdx == i"
              >
                <component
                  :is="widgetComponent"
                  class="widget-component"
                  :widgetMeta="widgetMeta"
                />
              </div>
            </div>
          </draggable>
          <p v-if="!widgets || widgets.length <= 0">
            Select an item from the widget toolbar to add it to the task
          </p>
        </b-card>
      </div>
      <TaskPreviewModal
        :show="showPreview"
        :task="task"
        :onClose="() => (showPreview = false)"
      ></TaskPreviewModal>
    </template>
    <template #modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-between">
        <button
          type="button"
          class="btn-outline-blue"
          @click.stop.prevent="cancel"
        >
          Close
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import draggable from "vuedraggable";
import WidgetToolbar from "../widgets/WidgetToolbar.vue";
import TextEdit from "../widgets/TextEdit";
import Photo from "../widgets/Photo";
import Video from "../widgets/Video";
import Upload from "../widgets/Upload";
import Download from "../widgets/Download";
import Calendar from "../widgets/Calendar";
import Questionnaire from "../widgets/Questionnaire";
import TaskPreview from "./TaskPreview";
import TaskPreviewModal from "./TaskPreviewModal";
import BrowserContainer from "@/components/templates/BrowserContainer";
import ContentContainer from "@/components/templates/ContentContainer";
import HelpModal from "@/components/shared/HelpModal";
import { mapGetters } from "vuex";

export default {
  components: {
    draggable,
    WidgetToolbar,
    TextEdit,
    Photo,
    Upload,
    Download,
    Questionnaire,
    Video,
    Calendar,
    TaskPreview,
    BrowserContainer,
    TaskPreviewModal,
    HelpModal,
    ContentContainer,
  },
  props: ["showModal", "taskData", "onClose", "setupMeta"],
  data() {
    return {
      loading: false,
      widgets: [],
      widgetMeta: null,
      groupIdx: -1,
      taskIdx: -1,
      show: false,
      widgetComponent: "",
      widgetIdx: null,
      setWidgetName: "",
      showPreview: false,
      task: null,
      saved: true,
      toastCount: 0,
      duration_options: [
        { value: null, text: "Edit duration..." },
        { value: 0, text: "1 Hour" },
        { value: 1, text: "5 Hours" },
        { value: 2, text: "10 Hours" },
        { value: 3, text: "12 Hours" },
        { value: 4, text: "24 Hours" },
        { value: 5, text: "One Week" },
        { value: 6, text: "Two Weeks" },
        { value: 7, text: "One Month" },
      ],
      assignee_options: [
        { value: null, text: "Please select a user to assign this task to..." },
      ],
      showEditModal: this.showModal,
    };
  },
  computed: {
    ...mapGetters([
      "getTaskInContext",
      "getWorkflowInContext",
      "getWorkflowMeta",
      "getClientInContext",
      "getGroups",
      "getClientUserData",
      "getWidgetIcons",
    ]),
  },
  created() {
    this.$bus.$on("widgetSaved", async ($event) => {
      if (this.widgets[this.widgetIdx]) {
        if ($event.name != "") {
          this.$set(this.widgets[this.widgetIdx].widget, "title", $event.name);
        }
        [this.widgets[this.widgetIdx].widget.data, this.show] = [$event, false];

        const success = await this.saveWidgets();
        if (success) this.clearWidget();
        this.setupMeta();
      }
    });
    this.$bus.$on("addWidget", ($event) => {
      this.widgets.push($event);
      [this.widgetComponent, this.widgetMeta, this.show, this.widgetIdx] = [
        "",
        null,
        false,
        null,
      ];
      if ($event) {
        this.widgetIdx = this.widgets.length - 1;
        this.widgetComponent = this.widgets[this.widgetIdx].widget.type;
        this.show = true;
      }
    });
    this.$bus.$on("clearWidget", () => {
      this.clearWidget();
    });
  },
  async beforeDestroy() {
    this.$bus.$off("widgetSaved");
    this.$bus.$off("addWidget");
    this.$bus.$off("clearWidget");
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      try {
        let forceLoadMeta = false;
        if (
          !this.getWorkflowInContext ||
          !(
            this.getWorkflowInContext?.workflow_uuid ==
              this.$route?.params?.uuid &&
            this.getWorkflowInContext?.workflow_version ==
              this.$route?.params?.version
          )
        ) {
          let newRoute = null;
          if (
            !(await this.$store.dispatch("getWorkflow", {
              workflowUUID: this.$route?.params?.uuid,
              workflowVersion: this.$route?.params?.version,
            }))
          ) {
            await this.$store.dispatch("setWorkflowInContext", null);
            await this.$store.dispatch("setWorkflowMeta", null);
            newRoute = { name: "WorkflowOverview" };
          }

          if (newRoute != null) {
            this.$router.push(newRoute).catch(() => {});
            return;
          }

          // load the meta IFF the workflow had to be loaded
          forceLoadMeta = true;
        }

        this.workflow = this.getWorkflowInContext;

        if (
          forceLoadMeta ||
          this.getWorkflowMeta?.workflow_id != this.workflow.workflow_id ||
          !this.getWorkflowMeta?.meta?.groups
        ) {
          // always load the meta
          if (
            (await this.$store.dispatch("getWorkflowMeta", {
              workflowID: this.workflow.workflow_id,
              clientID: this.workflow.client_id,
            })) === false
          ) {
            // meta is bad, so we need to bail
            await this.$store.dispatch("setWorkflowInContext", null);
            await this.$store.dispatch("setWorkflowMeta", null);
            this.$router.push({ name: "WorkflowOverview" });
            return;
          }
        }

        this.meta = this.getWorkflowMeta.meta;

        // confirmed route must have params at this point -- confirm that the group & task COULD be valid
        // parse to integers to simplify legalization
        // let groupIdx =
        //   typeof this.$route.params.groupIdx == "string"
        //     ? parseInt(this.$route.params.groupIdx, 10)
        //     : this.$route.params.groupIdx;
        // let taskIdx =
        //   typeof this.$route.params.taskIdx == "string"
        //     ? parseInt(this.$route.params.taskIdx, 10)
        //     : this.$route.params.taskIdx;
        let { groupIdx, taskIdx } = this.taskData;

        if (groupIdx === undefined || taskIdx === undefined) return;

        if (
          isNaN(groupIdx) ||
          isNaN(taskIdx) ||
          !Number.isSafeInteger(groupIdx) ||
          !Number.isSafeInteger(taskIdx) ||
          groupIdx < 0 ||
          taskIdx < 0 ||
          !this.meta?.groups ||
          !Array.isArray(this.meta.groups) ||
          this.meta.groups.length < groupIdx ||
          !this.meta.groups[groupIdx].tasks ||
          !Array.isArray(this.meta.groups[groupIdx].tasks) ||
          this.meta.groups[groupIdx].tasks.length < taskIdx ||
          !this.meta.groups[groupIdx].tasks[taskIdx]
        ) {
          this.$router
            .push({
              name: "ClientWorkflowEditNew",
              params: {
                uuid: this.$route.params.uuid,
                version: this.$route.params.version,
              },
            })
            .catch(() => {});
          this.$store.dispatch("createErrors", "This task was not recognized");
          return;
        }

        this.task = this.meta.groups[groupIdx].tasks[taskIdx];

        if (!Array.isArray(this.task.widgetMeta)) {
          this.widgets = [];
        } else if (this.task.widgetMeta.length > 0) {
          for (let i in this.task.widgetMeta) {
            this.widgets.push(this.task.widgetMeta[i]);
          }
        }

        const userData = this.getClientUserData;
        if (userData && Array.isArray(userData) && userData.length > 0) {
          this.assignee_options = [
            {
              value: null,
              text: "Please select a user to assign this task to...",
            },
          ];
          for (let i = 0; i < userData.length; i++) {
            this.assignee_options.push({
              value:
                userData[i].manager_id ||
                userData[i].manager_cognito_id ||
                userData[i].sub,
              text:
                userData[i].name ||
                userData[i].email ||
                userData[i].manager_cognito_id,
            });
          }
        }

        this.groupIdx = groupIdx;
        this.taskIdx = taskIdx;

        await this.$bus.$emit("breadcrumbData", [
          {
            text: "Workflows",
            to: {
              name: "ClientWorkflowOverview",
            },
          },
          {
            text:
              (this.workflow.workflow_name.length > 50
                ? this.workflow.workflow_name.substr(0, 50) + "..."
                : this.workflow.workflow_name) + " Preview",
            to: {
              params: {
                uuid: this.$route.params.uuid,
                version: this.$route.params.version,
              },
              name: "ClientWorkflowPreview",
            },
          },
          {
            text: "Edit",
            to: {
              params: {
                uuid: this.$route.params.uuid,
                version: this.$route.params.version,
              },
              name: "ClientWorkflowEditNew",
            },
          },
          {
            text:
              "Group #" +
              (this.groupIdx + 1) +
              " Task #" +
              (this.taskIdx + 1) +
              " Editing",
            to: {
              params: {
                uuid: this.$route.params.uuid,
                version: this.$route.params.version,
                taskIdx: this.taskIdx,
                groupIdx: this.groupIdx,
              },
              name: "ClientWorkflowEditNew",
            },
          },
        ]);
      } finally {
        this.loading = false;
      }
    },
    async removeWidget(index) {
      this.widgets.splice(index, 1);
      const success = await this.saveWidgets();
      if (success) this.clearWidget();
      this.setupMeta();
    },
    setWidgetComponent(component, id) {
      if (this.widgets[id].widget.data != null) {
        this.widgetMeta = this.widgets[id].widget.data;
      } else {
        this.widgetMeta = [];
      }

      if (this.widgetIdx === id) {
        this.clearWidget();
      } else {
        this.widgetMeta.id = id;
        this.widgetComponent = component;
        this.show = true;
        this.widgetIdx = id;
      }
    },
    clearWidget() {
      this.widgetIdx = null;
    },
    async saveWidgets(returnToTaskOverview) {
      if (
        !this.getWorkflowMeta.meta.groups[this.groupIdx] ||
        !this.getWorkflowMeta.meta.groups[this.groupIdx].tasks[this.taskIdx]
      ) {
        await this.$store.dispatch(
          "createErrors",
          `Error saving widget meta: Cannot find group #${this.groupIdx} with task in slot #${this.taskIdx}`
        );
        return false;
      }

      this.getWorkflowMeta.meta.groups[this.groupIdx].tasks[
        this.taskIdx
      ].widgetMeta = this.widgets;

      const widgets = {
        workflowID: this.getWorkflowInContext.workflow_id,
        workflowUUID: this.getWorkflowInContext.workflow_uuid,
        workflowVersion: this.getWorkflowInContext.workflow_version,
        clientID: this.getWorkflowInContext.client_id,
        workflowMeta: this.getWorkflowMeta.meta,
      };

      await this.$store.dispatch("setWorkflowMeta", widgets);

      if (this.$store.state.errors.length <= 0) {
        this.makeToast();
        this.saved = true;
      }

      if (returnToTaskOverview) await this.returnToTasks();

      return true;
    },
    async returnToTasks() {
      this.$router
        .push({
          name: "ClientWorkflowEditNew",
          params: {
            uuid: this.$route.params.uuid,
            version: this.$route.params.version,
          },
          //path: `/workflows/${this.workflow.workflow_uuid}/${this.workflow.workflow_version}/edit`
        })
        .catch(() => {});
    },
    previewTask() {
      this.showPreview = true;
      this.saved = true;
      this.saveWidgets();
    },
    onDrop(e) {
      [
        this.widgetComponent,
        this.widgetMeta,
        this.show,
        this.widgetIdx,
        this.saved,
      ] = ["", null, false, null, false];
      if (e.added) {
        this.widgetIdx = e.added.newIndex;
        this.widgetComponent = this.widgets[this.widgetIdx].widget.type;
        this.show = true;
      }
    },
    makeToast() {
      this.toastCount++;
      this.$bvToast.toast("Your changes have been saved!", {
        title: "Alert",
        variant: "info",
        autoHideDelay: 5000,
      });
    },
  },
  watch: {
    showModal(val) {
      this.showEditModal = val;
    },
    taskData: {
      deep: true,
      handler(val, oldVal) {
        if (
          !oldVal ||
          (val && val?.groupIdx !== oldVal?.groupIdx) ||
          val?.taskIdx !== oldVal?.taskIdx
        ) {
          this.widgets = [];
          this.init();
        }
      },
    },
  },
};
</script>
