<template>
	<b-modal
		:title="`Task ${variant === 'preview-task' ? 'Details' : editTask ? 'Edit' : 'Create'}`"
		size="lg"
		v-model="show"
		@hidden="() => handleClose()"
	>
		<div class="task-create-form">
			<form class="text-left form w-100" v-on:submit="onSubmit()" v-if="!editTask">
				<div class="form-row">
					<label class="w-50">Task Title*: </label>
					<input type="text" class="form-control form-control-sm w-50" v-model="form.title" placeholder="Enter a title..." />
				</div>
				<div class="form-row">
					<label class="w-50">Duration Active*:</label>
					<b-form-select
						size="sm"
						class="w-50"
						v-model="ttl_setting"
						:options="duration_options"
						@change="resolveTaskTTL(form)"
					></b-form-select>
				</div>
				<template v-if="ttl_setting == 'custom'">
					<div class="form-row">
						<label class="w-50">Custom Duration: </label>
						<b-form-select
							size="sm"
							class="w-25"
							v-model="ttl_modifier"
							:options="ttl_modifier_options"
							@change="resolveTaskTTL(form)"
						></b-form-select>
						<input
							class="w-25 form-control form-control-sm"
							placeholder="# Of"
							type="number"
							v-model="ttl_modifier_amount"
							@change="resolveTaskTTL(form)"
						/>
					</div>
				</template>
				<template v-if="!form.autocomplete">
					<div class="form-row">
						<label class="w-50">Assignee: </label>
						<b-form-select size="sm" class="w-50" v-model="assignee_type" @change="selectAssigneeTypeTaskCreate">
							<b-form-select-option :value="null">Please select a manager or consumer...</b-form-select-option>
							<b-form-select-option value="consumer">Consumer</b-form-select-option>
							<b-form-select-option value="manager">Manager</b-form-select-option>
						</b-form-select>
					</div>
					<div class="form-row" v-if="assignee_type && assignee_type == 'manager'">
						<label class="w-50">User: </label>
						<b-form-select
							size="sm"
							class="w-50 mb-2"
							v-model="form.manager_uuid"
							:options="assignee_options"
							v-if="variant !== 'preview-task'"
						></b-form-select>
						<div v-else>
							{{ getOptionsDisplayFromValue(assignee_options, form.manager_uuid) }}
						</div>
					</div>
					<div class="form-row">
						<label class="w-50">Notify Type*: </label>
						<b-form-select size="sm" class="w-50" v-model="form.notification_type" @change="onSelected">
							<b-form-select-option :value="null">Please select one notification type...</b-form-select-option>
							<b-form-select-option value="SMS">Text Message</b-form-select-option>
							<b-form-select-option value="Email">E-mail</b-form-select-option>
						</b-form-select>
					</div>
					<div class="form-row">
						<label v-if="form.notification_type != null && notification_options.length > 0" class="w-50">Template:</label>
						<b-form-select
							v-if="form.notification_type != null && notification_options.length > 0"
							size="sm"
							class="w-50"
							v-model="form.notification_template_id"
							:options="notification_options"
						></b-form-select>
					</div>
				</template>
			</form>
			<form class="text-left w-100" v-on:submit="updateTask()" v-else-if="editTask && task">
				<ul class="nav nav-tabs" id="task-edit-tabs" role="tablist">
					<li class="nav-item" role="presentation">
						<button
							:class="`nav-link ${getCurrentTab === 'general' ? 'active' : ''}`"
							id="general-tab"
							data-toggle="tab"
							data-target="#general"
							type="button"
							role="tab"
							aria-controls="general"
							:aria-selected="getCurrentTab === 'general'"
							@click="switchTab('general')"
						>
							General
						</button>
					</li>
					<li class="nav-item" role="presentation">
						<button
							:class="`nav-link ${getCurrentTab === 'delay' ? 'active' : ''}`"
							id="delay-tab"
							data-toggle="tab"
							data-target="#delay"
							type="button"
							role="tab"
							aria-controls="delay"
							:aria-selected="getCurrentTab === 'delay'"
							@click="switchTab('delay')"
							:disabled="task.groupIdx === 0"
						>
							Delay
						</button>
					</li>
					<li class="nav-item" role="presentation">
						<button
							:class="`nav-link ${getCurrentTab === 'triggers' ? 'active' : ''}`"
							id="triggers-tab"
							data-toggle="tab"
							data-target="#triggers"
							type="button"
							role="tab"
							aria-controls="triggers"
							:aria-selected="getCurrentTab === 'triggers'"
							@click="switchTab('triggers')"
						>
							Triggers
						</button>
					</li>
				</ul>
				<div class="tab-content p-4 border-left border-right border-bottom " id="task-edit-tabs">
					<div v-show="getCurrentTab === 'general'">
						<div class="form-row">
							<label class="w-50">Task Title*: </label>
							<input
								type="text"
								class="form-control-sm form-control w-50"
								v-model="task.name"
								placeholder="Enter a title..."
								v-if="variant !== 'preview-task'"
							/>
							<div v-else>{{ task.name }}</div>
						</div>
						<div class="form-row">
							<label class="w-50">Duration Active*: </label>
							<b-form-select
								size="sm"
								class="w-50"
								v-model="ttl_setting"
								:options="duration_options"
								@change="resolveTaskTTL(task)"
								v-if="variant !== 'preview-task'"
							></b-form-select>
							<div v-else>
								{{ getOptionsDisplayFromValue(duration_options, ttl_setting) }}
							</div>
						</div>
						<template v-if="ttl_setting == 'custom'">
							<div class="form-row">
								<label class="w-50">Custom Duration: </label>
								<b-form-select
									size="sm"
									class="w-25"
									v-model="ttl_modifier"
									:options="ttl_modifier_options"
									@change="resolveTaskTTL(task)"
									:disabled="variant !== 'preview-task'"
								></b-form-select>
								<input
									class="w-25 form-control form-control-sm"
									placeholder="# Of"
									type="number"
									v-model="ttl_modifier_amount"
									@change="resolveTaskTTL(task)"
									:disabled="variant !== 'preview-task'"
								/>
							</div>
						</template>
						<template v-if="!task.autocomplete">
							<div class="form-row">
								<label class="w-50">Assignee: </label>
								<b-form-select
									size="sm"
									class="w-50"
									v-model="assignee_type"
									@change="selectAssigneeTypeTaskUpdate"
									v-if="variant !== 'preview-task'"
								>
									<b-form-select-option :value="null">Please select a manager or consumer...</b-form-select-option>
									<b-form-select-option value="consumer">Consumer</b-form-select-option>
									<b-form-select-option value="manager">Manager</b-form-select-option>
								</b-form-select>
								<div v-else style="text-transform: capitalize;">
									{{ assignee_type }}
								</div>
							</div>
							<div class="form-row" v-if="assignee_type && assignee_type == 'manager'">
								<label class="w-50">User:</label>
								<b-form-select
									size="sm"
									class="w-50 mb-2"
									v-model="task.manager_uuid"
									:options="assignee_options"
									v-if="variant !== 'preview-task'"
								></b-form-select>
								<div v-else>
									{{ getOptionsDisplayFromValue(assignee_options, task.manager_uuid) }}
								</div>
							</div>
							<div class="form-row">
								<label class="w-50">Notify Type*: </label>
								<b-form-select
									size="sm"
									class="w-50"
									v-model="task.notificationType"
									@change="onSelected"
									v-if="variant !== 'preview-task'"
								>
									<b-form-select-option :value="null">Please select one notification type...</b-form-select-option>
									<b-form-select-option value="SMS">Text Message</b-form-select-option>
									<b-form-select-option value="Email">E-mail</b-form-select-option>
								</b-form-select>
								<div v-else>
									{{ task.notificationType }}
								</div>
							</div>
							<div class="form-row">
								<label v-if="task.notificationType != null && notification_options.length > 1" class="w-50">Template:</label>
								<template v-if="task.notificationType != null && notification_options.length > 1">
									<b-form-select
										v-if="variant !== 'preview-task'"
										size="sm"
										class="w-50"
										v-model="task.notificationTemplateID"
										:options="notification_options"
									></b-form-select>
									<router-link
										v-else
										:to="{
											name: 'NotificationTemplateEdit',
											params: {
												notificationTemplateID: task.notificationTemplateID
											}
										}"
									>
										{{ getOptionsDisplayFromValue(notification_options, task.notificationTemplateID) }}
									</router-link>
								</template>
							</div>
						</template>
					</div>
					<div v-show="getCurrentTab === 'delay'">
						<div class="form-row">
							<h4>Delay Task</h4>
						</div>
						<div class="form-row">
							<span class="py-1 pr-1 mb-1">UNTIL</span>
							<div class="pr-1 mb-1 d-flex">
								<b-form-select
									size="sm"
									v-model="delay_setting"
									:options="delay_duration_options"
									:disabled="variant === 'preview-task'"
									v-if="variant === 'edit-task'"
									@change="handleDelayChange(task)"
								></b-form-select>
								<span v-else class="my-auto">{{ getDelayDisplay(task).toUpperCase() }}</span>
							</div>
							<template v-if="!task.autocomplete">		
								<template v-if="delay_setting == 'custom'">
									<div class="pr-1 mb-1 custom-input" v-if="variant === 'edit-task'">
										<input
											class="form-control form-control-sm"
											placeholder="# Of"
											type="number"
											v-model="delay"
											:disabled="variant === 'preview-task'"
										/>
									</div>
									<div class="pr-1 mb-1 d-flex">
										<b-form-select
											size="sm"
											v-model="delay_modifier"
											:options="itt_modifier_options"
											:disabled="variant === 'preview-task'"
											v-if="variant === 'edit-task'"
										></b-form-select>
										<span v-else class="my-auto">{{ getDelayModifierDisplay(task).toUpperCase() }}</span>
									</div>
								</template>
								<div class="pr-1 mb-1 d-flex">
									<b-form-select 
										size="sm" 
										v-model="delay_direction"
										:disabled="variant === 'preview-task'"
										v-if="variant === 'edit-task'"
									>
										<b-form-select-option :value="-1" v-if="delay_meta !== 'task_end'">Before</b-form-select-option>
										<b-form-select-option :value="1">After</b-form-select-option>
									</b-form-select>
									<span v-else class="my-auto">{{ delay_direction === 1 ? 'AFTER' : 'BEFORE' }}</span>
								</div>
								<div class="pr-1 mb-1 d-flex">
									<b-form-select
										size="sm"
										v-model="delay_meta"
										:options="getDelayMetaOptions"
										@change="handleDelayChange(task)"
										:disabled="variant === 'preview-task'"
										v-if="variant === 'edit-task'"
									></b-form-select>
									<span v-else class="my-auto">{{ getDelayMetaDisplay(task).toUpperCase() }}</span>
								</div>
							</template>
						</div>
					</div>
					<div v-show="getCurrentTab === 'triggers'">
						<div class="form-row">
							<h4>Trigger Timeline</h4>
						</div>
						<div class="form-row d-flex" v-if="variant !== 'preview-task' || (initiateTimeline && task.initiateTimeline)">
							<span class="py-1 pr-1 mb-1">START</span>
							<div class="pr-1 mb-1" v-if="variant !== 'preview-task'">
								<b-form-select
									size="sm"
									v-model="initiateTimeline"
									:options="getWorkflowOptions"
									@change="handleInitiateTimelineChange(task)"
								></b-form-select>
							</div>
							<template v-if="initiateTimeline && task.initiateTimeline">
								<div class="pr-1 mb-1" v-if="variant !== 'preview-task'">
									<b-form-select
										size="sm"
										v-model="initiateTimelineTiming"
										:options="itt_duration_options"
										@change="resolveInitiateTimelineTTL(task)"
									></b-form-select>
								</div>
								<template v-if="initiateTimelineTiming == 'custom' && variant !== 'preview-task'">
									<div class="pr-1 mb-1 custom-input">
										<input
											class="form-control form-control-sm"
											placeholder="# Of"
											type="number"
											v-model="itt_modifier_amount"
											@change="resolveInitiateTimelineTTL(task)"
											:disabled="variant === 'preview-task'"
										/>
									</div>
									<div class="pr-1 mb-1">
										<b-form-select
											size="sm"
											v-model="itt_modifier"
											:options="itt_modifier_options"
											@change="resolveInitiateTimelineTTL(task)"
											:disabled="variant === 'preview-task'"
										></b-form-select>
									</div>
								</template>
								<div class="pr-1 mb-1" v-if="variant !== 'preview-task'">
									<b-form-select size="sm" v-model="task.initiateTimeline.offsetModifierDirection">
										<b-form-select-option :value="-1">Before</b-form-select-option>
										<b-form-select-option :value="1">After</b-form-select-option>
									</b-form-select>
								</div>
								<div class="pr-1 mb-1" v-if="variant !== 'preview-task'">
									<b-form-select
										size="sm"
										v-model="initiateTimelineMeta"
										:options="getTimelineMetaOptions"
										@change="resolveInitiateTimelineTTL(task)"
									></b-form-select>
								</div>
								<span v-if="variant === 'preview-task'" class="py-1 pr-1 mb-1 uppercase">{{
									getInitiateTimelineTimingDisplay(task)
								}}</span>
								<template
									v-if="Array.isArray(getTaskDataBagOptions) && getTaskDataBagOptions.length > 1 && variant !== 'preview-task'"
								>
									<span class="py-1 pr-1 mb-1">WHEN</span>
									<div class="pr-1 mb-1">
										<b-form-select
											size="sm"
											v-model="initiateTimelineCondition"
											:options="getTaskDataBagOptions"
										></b-form-select>
									</div>
									<template v-if="initiateTimelineCondition">
										<span class="py-1 pr-1 mb-1">IS</span>
										<div class="pr-1 mb-1">
											<template v-if="Array.isArray(getConditionValues)">
												<b-form-select
													size="sm"
													v-model="initiateTimelineConditionValue"
													:options="getConditionValues"
												></b-form-select>
											</template>
											<template v-else>
												<input class="form-control form-control-sm" size="sm" :value="initiateTimelineConditionValue" />
											</template>
										</div>
										<span class="py-1 pr-1 mb-1">OTHERWISE START</span>
										<div class="pr-1 mb-1">
											<b-form-select
												size="sm"
												v-model="task.initiateTimeline.timelines[0]"
												:options="getWorkflowOptions"
												v-if="variant !== 'preview-task'"
											></b-form-select>
											<div v-else>
												Initiate
												{{ getOptionsDisplayFromValue(getWorkflowOptions, task.initiateTimeline.timelines[0]) }}
											</div>
										</div>
									</template>
								</template>
								<template v-else-if="variant === 'preview-task' && getInitiateTimelineConditionDisplay(task)">
									<span class="py-1 pr-1 mb-1">WHEN {{ getInitiateTimelineConditionDisplay(task) }}</span>
								</template>
							</template>
						</div>
						<div v-else>
							No timelines will be triggered after the completion of this task.
						</div>
					</div>
				</div>
			</form>
		</div>
		<template #modal-footer="{ close }">
			<div class="w-100 d-flex justify-content-between">
				<button type="button" class="btn-outline-blue" @click.stop.prevent="close">
					Close
				</button>
				<div v-if="variant === 'preview-task'"></div>
				<button v-else-if="editTask" type="submit" class="btn-blue" @click.prevent="updateTask">
					Update Task
				</button>
				<button v-else type="submit" class="btn-blue" @click.prevent="onSubmit">
					Add Task
				</button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import awsexports from '../../../aws-exports';
export default {
	props: ['task', 'show', 'onClose', 'variant', 'consumerMetaDates', 'groups'],
	data() {
		return {
			form: {
				title: '',
				manager_uuid: null,
				notification_type: null,
				notification_template_id: null,
				autocomplete: false,
				ttl: null,
				ttl_modifier: null
			},
			ttl_setting: null,
			ttl_modifier: 86400,
			ttl_modifier_options: [
				{ value: null, text: 'Please select one...' },
				{ value: 86400, text: 'Days' },
				{ value: 604800, text: 'Weeks' },
				{ value: 31536000, text: 'Years' }
			],
			ttl_modifier_amount: 0,
			assignee_type: null,
			assignee_options: [{ value: null, text: 'Please select a user to assign this task to...' }],
			notification_options: [],
			duration_options: [
				{ value: null, text: 'Please select one...' },
				{ value: 86400, text: '1 Day' },
				{ value: 172800, text: '2 Days' },
				{ value: 259200, text: '3 Days' },
				{ value: 604800, text: '1 Week' },
				{ value: 1209600, text: '2 Weeks' },
				{ value: 15768000, text: '26 Weeks' },
				{ value: 31536000, text: '1 Year' },
				{ value: 'custom', text: 'Custom' }
			],
			editTask: false,
			showModal: false,
			initiateTimeline: null,
			initiateTimelineTiming: 0,
			initiateTimelineMeta: 'timeline_end',
			initiateTimelineCondition: null,
			initiateTimelineConditionValue: '',
			itt_modifier: 86400, // itt - initiate timeline
			itt_modifier_options: [
				{ value: null, text: 'Please select one...' },
				awsexports.debug === '1' && { value: 60, 'text': 'Minutes' },
				{ value: 86400, text: 'Days' },
				{ value: 604800, text: 'Weeks' },
				{ value: 31536000, text: 'Years' }
			].filter((opt) => opt),
			itt_modifier_amount: 0,
			// initiate timeline duration options
			itt_duration_options: [
				{ value: 0, text: 'Immediately' },
				{ value: 86400, text: '1 Day' },
				{ value: 172800, text: '2 Days' },
				{ value: 259200, text: '3 Days' },
				{ value: 604800, text: '1 Week' },
				{ value: 1209600, text: '2 Weeks' },
				{ value: 15768000, text: '26 Weeks' },
				{ value: 31536000, text: '1 Year' },
				{ value: 'custom', text: 'Custom' }
			],
			delay_duration_options: [
				{ value: 0, text: 'Immediately' },
				{ value: 86400, text: '1 Day' },
				{ value: 172800, text: '2 Days' },
				{ value: 259200, text: '3 Days' },
				{ value: 604800, text: '1 Week' },
				{ value: 1209600, text: '2 Weeks' },
				{ value: 15768000, text: '26 Weeks' },
				{ value: 31536000, text: '1 Year' },
				{ value: 'custom', text: 'Custom' }
			],
			active_tab: 'general',
			delay: 0,
			delay_setting: 0,
			delay_modifier: 1,
			delay_meta: 'task_end',
			delay_direction: 1
		};
	},
	computed: {
		...mapGetters(['getUser', 'getClientUserData', 'getClientNotificationTemplateData', 'getWorkflows', 'getClientInContext']),
		getTaskDataBagOptions() {
			try {
				const options = [
					{
						value: null,
						text: 'Complete'
					}
				];

				if (!this.task) return options;

				const dataBagOptions = [];
				const groups = this.groups || [];
				// get all tasks before the current task
				let tasks = groups.filter((_, index) => index < this.task.groupIdx).flatMap((group) => group.tasks);
				tasks = [this.task].concat(tasks);

				tasks
					.map((task) => task.widgetMeta)
					.filter((wm) => Array.isArray(wm))
					.forEach((widgetMeta) => {
						const values = widgetMeta.flatMap((wm) => {
							const content = wm.widget?.data?.content;
							if (!Array.isArray(content)) {
								return [];
							}

							return wm.widget?.data?.content?.map((content) => {
								return {
									value: content.label,
									text: content.label
								};
							});
						});

						dataBagOptions.push(...values);
					});

				return options.concat(dataBagOptions);
			} catch (err) {
				// pass
			}

			return [];
		},
		getTimelineMetaOptions() {
			// legacy value, Task End is correct
			let metaOptions = [{ value: 'timeline_end', text: 'Task End' }];

			if (this.task?.initiateTimeline?.offsetModifierDirection === -1) {
				metaOptions = [];
			}

			return metaOptions.concat(this.consumerMetaDates);
		},
		getDelayMetaOptions() {
			let metaOptions = [{ value: 'task_end', text: this.task?.groupIdx === 0 ? 'Timeline Start' : 'Previous Task' }];

			if (this.task?.initiateTimeline?.offsetModifierDirection === -1) {
				metaOptions = [];
			}

			return metaOptions.concat(this.consumerMetaDates);
		},
		getConditionValues() {
			try {
				if (!this.task) return null;

				const groups = this.groups || [];
				let tasks = groups.filter((_, index) => index < this.task.groupIdx).flatMap((group) => group.tasks);
				tasks = [this.task].concat(tasks);

				const dataBag = tasks
					.flatMap((task) => task.widgetMeta)
					.flatMap((wm) => wm?.widget?.data?.content)
					.filter((content) => content);

				const dataBagItem = Object.values(dataBag).find((db) => db.label === this.initiateTimelineCondition);

				if (!dataBagItem || !Array.isArray(dataBagItem.options)) return null;

				return dataBagItem.options;
			} catch (err) {
				// pass
			}

			return null;
		},
		getWorkflowOptions() {
			return [{ value: null, text: 'None' }].concat(
				this.getWorkflows.map((workflow) => ({
					value: workflow.workflow_uuid,
					text: workflow.workflow_name
				}))
			);
		},
		getCurrentTab() {
			return this.active_tab;
		}
	},
	async created() {
		this.init();
	},
	async mounted() {},
	methods: {
		async init() {
			[
				this.assignee_type,
				this.form.notification_type,
				this.form.notification_template_id,
				this.form.ttl,
				this.form.manager_uuid
			] = [null, null, null, null, null];
			if (this.getUser) {
				let userData = this.getClientUserData;
				if (userData && Array.isArray(userData) && userData.length > 0) {
					this.assignee_options = [
						{
							value: null,
							text: 'Please select a user to assign this task to...'
						}
					];
					for (let i = 0; i < userData.length; i++) {
						this.assignee_options.push({
							value: userData[i].manager_id || userData[i].manager_cognito_id || userData[i].sub,
							has_phone: userData[i]?.phone_number ? true : false,
							text: userData[i].name || userData[i].email || userData[i].manager_cognito_id
						});
					}
				} else {
					this.assignee_options = [{ value: null, text: this.getUser.attributes.name }];
				}
			}
			if (this.task) {
				// setupi durations correctly.
				// find ttl setting, if applicable
				let currentTTL = this.task.ttl && !isNaN(this.task.ttl) && this.task.ttl > 0 ? this.task.ttl : null;

				let row = undefined;
				if (currentTTL) {
					row = this.duration_options.find((item) => {
						return currentTTL == item.value;
					});

					if (!this.task.ttl_modifier || isNaN(this.task.ttl_modifier) || this.task.ttl_modifier <= 1) {
						this.ttl_modifier = 1;
					} else {
						this.ttl_modifier = parseInt(this.task.ttl_modifier.toString(), 10);
					}
				} else {
					// auto custom 1 day
					this.ttl_modifier = 86400;
				}

				this.ttl_setting = row && row.value !== undefined ? row.value : 'custom';
				if (this.ttl_setting == 'custom') {
					// means: modifier is a value > 1 AND currentTTL > 0
					this.ttl_modifier_amount = Math.floor(currentTTL / this.ttl_modifier);
				}
				this.resolveTaskTTL(this.task);

				this.resolveInitiateTimeline(this.task, true);
				this.resolveInitialDelay(this.task);

				if (this.task.manager_uuid) {
					this.assignee_type = 'manager';
				} else {
					this.assignee_type = 'consumer';
				}
				this.selectAssigneeTypeTaskUpdate(true);
				this.editTask = true;
			}
			await this.$store.dispatch('getActiveWorkflows', { clientid: this.getClientInContext.client_id });
		},
		handleClose() {
			this.initiateTimeline = null;
			this.active_tab = 'general';
			this.onClose();
		},
		getInitiateTimelineTimingDisplay(task) {
			const durationOption = this.itt_duration_options.find((db) => db.value === task.initiateTimeline.offsetModifierAmount)
				?.text;
			const offsetDirection = task.initiateTimeline.offsetModifierDirection === -1 ? 'before' : 'after';
			const offsetMeta =
				this.consumerMetaDates.find((consumer) => consumer.value === task.initiateTimeline.offsetMeta)?.text || 'Timeline End';

			if (!durationOption || !offsetDirection || !offsetMeta) {
				return 'N/A';
			}

			return `${durationOption} ${offsetDirection} ${offsetMeta}`;
		},
		getInitiateTimelineConditionDisplay(task) {
			const conditionKey = task.initiateTimeline.offsetMetaCondition?.key;
			const conditionValue = task.initiateTimeline.offsetMetaCondition?.value;

			if (!conditionKey || !conditionValue) {
				return '';
			}

			return `"${conditionKey}" IS "${conditionValue}"`;
		},
		getDelayDisplay(payloadContainer) {
			const value = payloadContainer?.delay?.delay || payloadContainer?.delay?.delay_setting;
			let option = this.delay_duration_options.find((opt) => opt.value === value);

			return option?.text || value?.toString() || 'Immediately';
		},
		getDelayModifierDisplay(payloadContainer) {
			const value = payloadContainer?.delay?.delay_modifier || 1;
			const modifier = this.itt_modifier_options.find((opt) => opt.value === value);
			const singular = this.delay_setting === 1 || this.delay === 1;
			const text = modifier?.text || '';

			return singular ? text.slice(0, -1) : text;
		},
		getDelayMetaDisplay(payloadContainer) {
			const value = payloadContainer?.delay?.delay_meta || 'task_end';
			const meta = this.getDelayMetaOptions.find((opt) => opt.value === value);
			return meta?.text || 'TASK END';
		},
		async selectAssigneeTypeTaskCreate() {
			/*if (this.assignee_type == 'consumer') {
				this.form.notification_type = 'Email';
			} else {
				this.form.notification_type = null;
			}*/
			this.onSelected();
		},
		async selectAssigneeTypeTaskUpdate(isInit) {
			if (this.task) {
				// don't blow out only when you first create form.
				if (this.assignee_type == 'consumer') {
					this.task.manager_uuid = null;
				}
				this.onSelected();
			}
		},
		async onSelected() {
			let templates = this.getClientNotificationTemplateData;

			let defaultTemplateID = null;
			let taskType = this.form.notification_type || (this.task && this.task.notificationType ? this.task.notificationType : '');
			if (!taskType) {
				this.notification_options = [];
				return;
			}
			this.notification_options = [{ value: null, text: 'Select notification template...' }];
			if (templates && Array.isArray(templates) && templates.length > 0) {
				for (let i = 0; i < templates.length; i++) {
					if (templates[i].email_subject == null && taskType == 'SMS') {
						this.notification_options.push({
							value: templates[i].notification_template_id,
							text: templates[i].notification_template_name
						});
						if (templates[i].notification_template_name == 'Default SMS') {
							defaultTemplateID = templates[i].notification_template_id;
						}
					} else if (templates[i].email_subject != null && taskType == 'Email') {
						this.notification_options.push({
							value: templates[i].notification_template_id,
							text: templates[i].notification_template_name + ' - ' + templates[i].email_subject
						});
						if (templates[i].notification_template_name == 'Default E-Mail') {
							defaultTemplateID = templates[i].notification_template_id;
						}
					}
				}
			}
			this.form.notification_template_id = defaultTemplateID;
		},
		updateTask() {
			let errors = [];
			if (!this.assignee_type) {
				errors.push('Please select an assignee for this task');
			}

			if (!this.task.autocomplete) {
				if (!this.task.notificationType) {
					errors.push('Please select a notification type for this task');
				}
			} else {
				if (
					!confirm(
						'This will delete all task widgets set for this task since it will notify nobody. If this is acceptable, please confirm. There is no undoing this action.'
					)
				) {
					return;
				}
				this.assignee_type = 'consumer';
				this.task.notificationType = null;
				this.task.notificationTemplateID = null;
			}

			if (!this.task.ttl || !this.task.ttl_modifier || this.task.ttl <= 0 || this.task.ttl_modifier <= 0) {
				errors.push('Please select a valid duration for this task');
			}

			if (this.assignee_type == 'manager') {
				if (this.task.manager_uuid == null) {
					errors.push('Please select a manager to complete this task');
				} else if (this.task.notificationType == 'SMS') {
					for (let i in this.assignee_options) {
						if (this.assignee_options[i].value == this.task.manager_uuid) {
							if (!this.assignee_options[i].hasPhone) {
								errors.push(
									'This task requires a phone number, and this manager does not have one assigned. Please select a different manager'
								);
							}
							break;
						}
					}
				}
			}

			if (this.initiateTimeline && this.task.initiateTimeline) {
				const chosenWorkflows = Object.values(this.task.initiateTimeline.timelines || {}).filter((wf) => wf);

				if (chosenWorkflows.length === 0) {
					errors.push('Please select a valid timeline for this task to initiate');
				}
			}

			if (errors.length > 0) {
				this.$store.dispatch('createErrors', errors);
				return;
			}

			if (this.assignee_type == 'consumer') {
				this.task.manager_uuid = null;
			}

			if (this.initiateTimeline) {
				if (this.initiateTimelineCondition) {
					this.task.initiateTimeline.offsetMetaCondition = {
						key: this.initiateTimelineCondition,
						value: this.initiateTimelineConditionValue
					};
				} else {
					this.task.initiateTimeline.offsetMetaCondition = {
						key: null,
						value: null
					};
					this.task.initiateTimeline.timelines[0] = null;
				}

				if (this.initiateTimelineMeta) {
					this.task.initiateTimeline.offsetMeta = this.initiateTimelineMeta;
				} else {
					this.task.initiateTimeline.offsetMeta = null;
				}
			} else {
				this.task.initiateTimeline = null;
			}

			if (this.delay_setting) {
				const delayAmount = parseInt(this.delay_setting === 'custom' ? this.delay : this.delay_setting) || 0;

				this.task.delay = {
					delay: delayAmount,
					delay_modifier: this.delay_modifier || 1,
					delay_direction: this.delay_direction || 1,
					delay_meta: this.delay_meta
				};
			} else {
				this.task.delay = null;
			}

			let updatedTask = {
				title: this.task.name,
				manager_uuid: this.task.autocomplete ? null : this.task.manager_uuid,
				notification_type: this.task.autocomplete ? null : this.task.notificationType,
				notification_template_id: this.task.autocomplete ? null : this.task.notificationTemplateID,
				autocomplete: this.task.autocomplete,
				ttl: this.task.ttl,
				ttl_modifier: this.task.ttl_modifier || 1,
				initiateTimeline: this.task.initiateTimeline || null,
				delay: this.task.delay,
			};

			this.$emit('clicked', updatedTask);
		},
		switchTab(newTab) {
			this.active_tab = newTab;
		},
		onSubmit() {
			let errors = [];

			if (!this.form.autocomplete) {
				if (!this.form.notification_type) {
					errors.push('Please select a notification type for this task');
				}
			} else {
				this.form.notification_type = null;
				this.form.notification_template_id = null;
				// force to consumer: TODO MAKE THIS BETTER LATER
				this.assignee_type = 'consumer';
			}

			if (!this.assignee_type) {
				errors.push('Please select an assignee for this task');
			}

			if (!this.form.ttl || !this.form.ttl_modifier || this.form.ttl <= 0 || this.form.ttl_modifier <= 0) {
				errors.push('Please select a duration for this task');
			}

			if (this.assignee_type == 'consumer') {
				this.form.manager_uuid = null;
			}

			if (this.assignee_type == 'manager') {
				if (this.form.manager_uuid == null) {
					errors.push('Please select a manager to complete this task');
				} else if (this.form.notification_type == 'SMS') {
					for (let i in this.assignee_options) {
						if (this.assignee_options[i].value == this.form.manager_uuid) {
							if (!this.assignee_options[i].hasPhone) {
								errors.push(
									'This task requires a phone number, and this manager does not have one assigned. Please select a different manager'
								);
							}
							break;
						}
					}
				}
			}

			if (errors.length > 0) {
				this.$store.dispatch('createErrors', errors);
				return;
			}

			this.$root.$emit('bv::toggle::collapse', 'add-task');
			this.$emit('clicked', this.form);
			this.showModal = false;
		},
		handleInitiateTimelineChange(payloadContainer) {
			payloadContainer.initiateTimeline.timelines[1] = this.initiateTimeline;
			this.resolveInitiateTimeline(payloadContainer);
		},
		resolveInitialDelay(payloadContainer) {
			if (typeof payloadContainer.delay === 'object') {
				this.delay_meta = payloadContainer.delay?.delay_meta || 'task_end';
				this.delay_direction = payloadContainer.delay?.delay_direction || 1;
				this.delay_modifier = payloadContainer.delay?.delay_modifier || 1;

				// check for custom timing
				const timing = payloadContainer.delay?.delay;
				if (
					Number.isInteger(timing) &&
					!this.delay_duration_options.find((opt) => opt.value === timing)
				) {
					try {
						this.itt_modifier = this.itt_modifier_options
							.slice()
							.sort((a, b) => a.value < b.value)
							.reduce((a, b) => {
								return b.value && Number.isInteger(timing * b.value) && b.value > a ? b.value : a;
							}, 0);
						this.delay = timing;
						this.delay_setting = 'custom';
					} catch (err) {
						// pass
					}
				} else {
					this.delay_setting = payloadContainer.delay?.delay || 0;
					this.delay = 0;
				}
			}
		},
		handleDelayChange(payloadContainer) {

			// do not allow user to choose to delay before task end
			if (this.delay_meta === 'task_end' && this.delay_direction === -1) {
				this.delay_direction = 1;
			}

			if (this.delay_setting === -1) {
				payloadContainer.autocomplete = true;
				payloadContainer.delay = null;
			} else {
				payloadContainer.autocomplete = false;
			}

			// keep delay_modifier only for custom delay values
			if (this.delay_setting !== 'custom') {
				this.delay_modifier = 1;
			}
		},
		resolveInitiateTimelineTTL(ttlPayloadContainer) {
			let ttl = 0;
			let ttlModifier = 1;
			if (typeof this.initiateTimelineTiming == 'string' && this.initiateTimelineTiming == 'custom') {
				if (!isNaN(this.itt_modifier) && !isNaN(this.itt_modifier_amount)) {
					let ttlMod = parseInt((this.itt_modifier || '').toString(), 10);
					let ttlAmt = parseInt((this.itt_modifier_amount || '').toString(), 10);
					if (ttlMod && ttlAmt && ttlMod > 0 && ttlAmt > 0) {
						let potentialTTL = ttlMod * ttlAmt;
						if (!Number.isSafeInteger(potentialTTL)) {
							ttlModifier = 31536000;
							ttl = 100 * ttlModifier;
						} else {
							ttl = potentialTTL;
							ttlModifier = ttlMod;
						}
					}
				}
			} else {
				let potentialTTL = parseInt((this.initiateTimelineTiming || '').toString(), 10);
				// if FALSE or <0
				if (!potentialTTL || isNaN(potentialTTL) || potentialTTL < 0) {
					ttl = 0;
				} else if (!Number.isSafeInteger(potentialTTL)) {
					ttl = Number.MAX_SAFE_INTEGER;
				} else if (potentialTTL > 0) {
					ttl = potentialTTL;
				}
				ttlModifier = 1;
			}

			if (ttlPayloadContainer.initiateTimeline) {
				ttlPayloadContainer.initiateTimeline.offsetModifierAmount = ttl;
			}
		},
		resolveTaskTTL(ttlPayloadContainer) {
			let ttl = 0;
			let ttlModifier = 1;
			if (typeof this.ttl_setting == 'string' && this.ttl_setting == 'custom') {
				if (!isNaN(this.ttl_modifier) && !isNaN(this.ttl_modifier_amount)) {
					let ttlMod = parseInt((this.ttl_modifier || '').toString(), 10);
					let ttlAmt = parseInt((this.ttl_modifier_amount || '').toString(), 10);
					if (ttlMod && ttlAmt && ttlMod > 0 && ttlAmt > 0) {
						let potentialTTL = ttlMod * ttlAmt;
						if (!Number.isSafeInteger(potentialTTL)) {
							ttlModifier = 31536000;
							ttl = 100 * ttlModifier;
						} else {
							ttl = potentialTTL;
							ttlModifier = ttlMod;
						}
					}
				}
			} else {
				let potentialTTL = parseInt((this.ttl_setting || '').toString(), 10);
				// if FALSE or <0
				if (!potentialTTL || isNaN(potentialTTL) || potentialTTL < 0) {
					ttl = 0;
				} else if (!Number.isSafeInteger(potentialTTL)) {
					ttl = Number.MAX_SAFE_INTEGER;
				} else if (potentialTTL > 0) {
					ttl = potentialTTL;
				}
				ttlModifier = 1;
			}
			ttlPayloadContainer.ttl = ttl;
			ttlPayloadContainer.ttl_modifier = ttlModifier;
		},
		resolveInitiateTimeline(payloadContainer, initial = false) {
			if (initial) {
				this.initiateTimeline = null;
				if (!payloadContainer.initiateTimeline) {
					payloadContainer.initiateTimeline = {
						workflowID: null,
						offsetModifier: this.itt_modifier,
						offsetModifierAmount: this.itt_modifier_amount,
						offsetModifierDirection: 1,
						offsetMeta: null,
						offsetMetaCondition: {
							key: null,
							value: null
						},
						timelines: {
							0: null,
							1: null
						}
					};
				} else {
					this.initiateTimeline = payloadContainer.initiateTimeline.timelines[1];
					this.initiateTimelineMeta = payloadContainer.initiateTimeline.offsetMeta || 'timeline_end';
					this.initiateTimelineCondition = payloadContainer.initiateTimeline.offsetMetaCondition.key;
					this.initiateTimelineConditionValue = payloadContainer.initiateTimeline.offsetMetaCondition.value;
					this.initiateTimelineTiming = payloadContainer.initiateTimeline.offsetModifierAmount;
				}
			}

			if (this.initiateTimeline) {
				// check for custom timing
				if (
					Number.isInteger(this.initiateTimelineTiming) &&
					!this.itt_duration_options.find((opt) => opt.value === this.initiateTimelineTiming)
				) {
					try {
						this.itt_modifier = this.itt_modifier_options
							.slice()
							.sort((a, b) => a.value < b.value)
							.reduce((a, b) => {
								return b.value && Number.isInteger(this.initiateTimelineTiming / b.value) && b.value > a ? b.value : a;
							}, 0);
						this.itt_modifier_amount = this.initiateTimelineTiming / (this.itt_modifier || 1);
						this.initiateTimelineTiming = 'custom';
					} catch (err) {
						// pass
					}
				}
			} else {
				payloadContainer.initiateTimeline = {
					workflowID: null,
					offsetModifier: this.itt_modifier,
					offsetModifierAmount: this.itt_modifier_amount,
					offsetModifierDirection: 1,
					offsetMeta: null,
					offsetMetaCondition: {
						key: null,
						value: null
					},
					timelines: {
						0: null,
						1: this.initiateTimeline
					}
				};
			}

			this.resolveInitiateTimelineTTL(payloadContainer);
		},
		getOptionsDisplayFromValue(options, value) {
			return options.find((option) => option.value === value)?.text;
		}
	},
	watch: {
		task() {
			this.init();
		}
	}
};
</script>

<style scoped>
.uppercase {
	text-transform: uppercase;
}
.custom-input {
	width: 64px;
}
</style>
