<template>
	<ContentContainer v-if="workflow">
		<div class="edit-disclaimer" v-if="editMode">
			<span>Edit Mode</span>
		</div>
		<template #title>
			<div class="d-flex">
				<h4 class="mr-1">{{ workflow.workflow_name }}</h4>
				<a class="mr-1 edit-title-button" role="button" v-b-modal.editWorkflowTitle v-if="editMode">
					<i class="fas fa-edit"></i>
				</a>
				<div>
					<b-badge v-if="editMode" variant="warning" size="sm">Draft</b-badge>
				</div>
			</div>
		</template>
		<template #header>
			<div class="row justify-content-between">
				<div class="text-right" v-if="workflow.workflow_finalized">
					<button class="btn-outline-blue mr-2" @click="handleNewVersion">
						{{ workflow.latest_version == workflow.workflow_version ? 'Create' : 'Edit' }}
						New Version
					</button>
					<button
						class="btn-blue mr-2"
						v-b-modal.createNewConsumerTimeline
						@click="
							() => {
								consumerSelected = null;
								$bvModal.show('createNewConsumerTimeline');
							}
						"
					>
						Initiate Timeline
					</button>
					<div>
						<HelpModal>
							<p>
								Tasks are web pages that are presented to an end user (either a Manager or a Consumer) at a specific point in
								the Workflow sequence in order to deliver content in the form of text, images, videos, and downloadable files.
								In addition, you can prompt the user to answer questions and upload files.
							</p>
							<p>
								Published workflows cannot be edited and will have to create a new version to edit. Creating a new version will
								not override the current version until it is published.
							</p>
							<p>
								Clicking the <i class="fas fa-ellipsis-v my-auto"></i> on a task will open a menu of actions. Clicking the
								Preview Task option or on a task's title / item will open a window that resembles what the consumer will see
								when the task is rendered.
							</p>
							<p>
								Initiating a timeline will create a new timeline.
							</p>
						</HelpModal>
					</div>
				</div>
				<div class="row" v-else>
					<button class="btn-outline-blue mr-2" @click="finalizeWorkflow(getWorkflowInContext)">
						Publish Workflow
					</button>
					<div>
						<HelpModal>
							<p>
								Tasks are web pages that are presented to an end user (either a Manager or a Consumer) at a specific point in
								the Workflow sequence in order to deliver content in the form of text, images, videos, and downloadable files.
								In addition, you can prompt the user to answer questions and upload files.
							</p>
							<p>
								Tasks can be reordered and grouped together by dragging them by their handle (
								<i class="fas fa-grip-vertical"></i> ). Dragging a task above or below another task will place them in the same
								group.
							</p>
							<p>
								Clicking the <i class="fas fa-ellipsis-v my-auto"></i> on a task will open a menu of actions. Clicking the Edit
								Widgets option or on a task's title / item will open a window to edit the task's widgets.
							</p>
							<p>
								Saved widgets can be edited until the workflow is finalized.
							</p>
						</HelpModal>
					</div>
				</div>
			</div>
		</template>

		<b-modal
			v-b-modal.editWorkflowTitle
			id="editWorkflowTitle"
			variant="light"
			dismissible
			class="w-100 mx-auto"
			title="Edit Workflow Name"
			size="lg"
		>
			<b-row>
				<b-col>
					<b-form-group label="Workflow Name" label-for="workflowTitle" label-cols="3" label-align="right">
						<b-form-input id="workflowTitle" v-model="newWorkflowTitle" placeholder="Workflow Title" />
					</b-form-group>
				</b-col>
			</b-row>
			<template #modal-footer="{ close }">
				<div class="w-100 d-flex justify-content-between">
					<button type="button" class="btn-outline-blue w-25" @click="close()">
						Close
					</button>
					<button class="btn-blue w-25" @click="editWorkflowTitle()">
						Save
					</button>
				</div>
			</template>
		</b-modal>

		<b-modal
			v-b-modal.createNewConsumerTimeline
			id="createNewConsumerTimeline"
			variant="light"
			dismissible
			class="w-100 mx-auto"
			title="Initiate Timeline"
		>
			<template v-if="Array.isArray(consumerSelectionOptions) && consumerSelectionOptions.length > 0">
				<b-row class="mb-2 mt-2">
					<b-col sm="12">
						<label class="h6">Consumer Options</label>
						<b-form-select
							:options="initializeTimelineConsumerOptions"
							class="b-select"
							@change="
								() => {
									consumerSelected = null;
									initializeTimelineSetConsumer();
								}
							"
							v-model="initializeTimelineWithExistingConsumer"
						></b-form-select>
					</b-col>
				</b-row>
				<b-row v-if="initializeTimelineWithExistingConsumer" class="mb-2 mt-2">
					<b-col sm="12">
						<label class="h6">Select Consumer</label>
						<b-form-select
							:options="consumerSelectionOptions"
							class="b-select"
							v-model="consumerSelected"
							@change="initializeTimelineSetConsumer"
						></b-form-select>
					</b-col>
				</b-row>
			</template>
			<template
				v-if="
					!Array.isArray(consumerSelectionOptions) ||
						consumerSelectionOptions.length == 0 ||
						consumerSelected ||
						!initializeTimelineWithExistingConsumer
				"
			>
				<label class="h6">Consumer E-mail<span class="text-danger">*</span></label>
				<input
					class="form-control w-100"
					type="text"
					v-model="form.consumerEmail"
					placeholder="E-mail"
					:readonly="consumerSelected != null ? true : false"
				/>
				<!-- TODO: MOVE THIS TO SHARED SECTION WITH CONSUMER OVERVIEW PORTION OF THIS! -->
				<template v-for="metaName in consumerMetaDisplayOrder">
					<b-row class="striped_form_row mb-2 mt-2" :key="metaName">
						<b-col sm="12">
							<label class="float-left ml-2 h6"
								>{{ getClientInContext.consumer_meta[metaName].name || metaName
								}}<span v-if="getClientInContext.consumer_meta[metaName].required" class="text-danger">*</span></label
							>
						</b-col>
						<template
							v-if="
								getClientInContext.consumer_meta[metaName].type == 'date' ||
									getClientInContext.consumer_meta[metaName].type == 'date + time'
							"
						>
							<b-col :sm="getClientInContext.consumer_meta[metaName].type == 'date' ? '12' : '6'">
								<b-form-datepicker
									:required="getClientInContext.consumer_meta[metaName].required"
									size="sm"
									locale="en"
									v-model="form.consumerMeta[metaName].value"
									placeholder="mm/dd/yyyy"
									style="height:100%"
								></b-form-datepicker>
							</b-col>
							<b-col v-if="getClientInContext.consumer_meta[metaName].type == 'date + time'" sm="6">
								<b-form-timepicker
									class="time-picker"
									:reqiured="getClientInContext.consumer_meta[metaName].required"
									v-model="form.consumerMeta[metaName].timeValue"
									locale="en"
									placeholder="00:00:00"
								></b-form-timepicker>
							</b-col>
						</template>
						<b-col
							sm="12"
							v-else-if="
								getClientInContext.consumer_meta[metaName].type == 'currency' ||
									getClientInContext.consumer_meta[metaName].type == 'number'
							"
						>
							<input
								type="number"
								:required="getClientInContext.consumer_meta[metaName].required"
								v-model="form.consumerMeta[metaName].value"
								class="form-control"
							/>
						</b-col>
						<b-col sm="12" v-else>
							<input
								type="text"
								:required="getClientInContext.consumer_meta[metaName].required"
								v-model="form.consumerMeta[metaName].value"
								class="form-control"
							/>
						</b-col>
					</b-row>
				</template>
				<b-row>
					<b-col xs="12" class="text-left small"> <span class="text-danger">*</span> = Required </b-col>
				</b-row>
			</template>
			<template #modal-footer>
				<div class="w-100 d-flex justify-content-between">
					<button
						type="button"
						class="btn-outline-blue"
						@click.stop.prevent="
							() => {
								resetForm();
								$bvModal.hide('createNewConsumerTimeline');
							}
						"
					>
						Cancel
					</button>
					<button class="btn-blue" @click.stop.prevent="createTimeline()">
						Create Timeline
					</button>
				</div>
			</template>
		</b-modal>
		<b-modal v-b-modal.confirmTaskRemove id="confirmTaskRemove" variant="light" dismissible class="w-50 mx-auto">
			<p>
				Are you sure you'd like to remove this task? This action can not be undone.
			</p>
			<div slot="modal-footer">
				<button
					class="btn-sm btn-outline-dark mx-2"
					@click="
						() => {
							removeTask(tdx, gdx);
							$bvModal.hide('confirmTaskRemove');
						}
					"
				>
					Remove Task
				</button>
				<button class="btn-sm btn-outline-danger mx-2" @click="$bvModal.hide('confirmTaskRemove')">
					Cancel
				</button>
			</div>
		</b-modal>
		<div class="text-center m-4" v-if="loading">
			<b-spinner variant="primary" label="Spinning"></b-spinner>
		</div>
		<div v-else>
			<div v-if="!groups || (groups.length <= 0 && !loading)" class="col">
				<TaskCreateModal
					:show="showTaskCreateModal"
					:onClose="() => (showTaskCreateModal = false)"
					@clicked="onTaskCreated"
					:consumerMetaDates="consumerMetaDates"
					:groups="groups"
				/>
			</div>
			<div class="container justify-content-center">
				<b-row>
					<div class="col-3 text-align-left pl-2">
						<small
							><span class="text-muted">Workflow UUID:&nbsp;</span
							><span class="font-weight-bold">{{ this.workflow.workflow_uuid }}</span></small
						>
					</div>
					<div class="col-2">
						<small
							><span class="text-muted">Workflow Version #:&nbsp;</span
							><span class="font-weight-bold">{{ this.workflow.workflow_version }}</span></small
						>
					</div>
					<div v-if="workflow.latest_version" class="col-2">
						<small
							><span class="text-muted">Latest Version #:&nbsp;</span
							><span class="font-weight-bold">{{ this.workflow.latest_version }}</span></small
						>
					</div>
					<div v-if="workflow.workflow_finalized" class="col-5">
						<small
							><span class="text-muted">Webhook URL:&nbsp;</span
							><span class="font-weight-bold"
								>/ext/client/{{ workflow.client_id }}/timeline/create/{{ workflow.workflow_uuid }}</span
							></small
						>
					</div>
				</b-row>
				<TaskCreateModal
					:show="taskCreateModalVariant === 'add-task'"
					:onClose="() => (taskCreateModalVariant = '')"
					@clicked="onTaskCreated"
					:variant="taskCreateModalVariant"
					:consumerMetaDates="consumerMetaDates"
					:groups="groups"
				/>
				<TaskCreateModal
					:show="taskCreateModalVariant === 'edit-task'"
					:onClose="() => (taskCreateModalVariant = '')"
					:task="editTask"
					@clicked="onTaskUpdated"
					:variant="taskCreateModalVariant"
					:consumerMetaDates="consumerMetaDates"
					:groups="groups"
				/>
				<TaskCreateModal
					:show="taskCreateModalVariant === 'preview-task'"
					:onClose="() => (taskCreateModalVariant = '')"
					:task="editTask"
					:variant="taskCreateModalVariant"
					:consumerMetaDates="consumerMetaDates"
					:groups="groups"
				/>
				<div class="task-overview-container">
					<div class="timeline-container-scoped">
						<TimelineView :groups="groups" class="mx-2" />
					</div>
					<div class="scroll-container mx-0 px-0">
						<div class="draggable-tasks ml-2">
							<template v-for="(group, groupIdx) in groups">
								<draggable
									class="col"
									:key="`GroupFiller-${groupIdx}`"
									group="taskgroup"
									v-model="groupFillers[groupIdx]"
									@change="
										(e) => {
											onDragEndGroupFiller(e, groupIdx);
										}
									"
								>
								</draggable>
								<draggable
									class="col mx-0 px-0"
									:key="`Group-${groupIdx}`"
									group="taskgroup"
									v-model="group.tasks"
									@end="onDragEnd"
									handle=".handle"
								>
									<div v-for="(task, taskIdx) in group.tasks" :key="`${task.name}-${groupIdx}-${taskIdx}`">
										<b-card class="task-card-scoped text-left">
											<div class="d-flex justify-content-between">
												<div class="my-auto handle cursor-pointer mr-1">
													<i class="fas fa-grip-vertical fa-lg"></i>
												</div>
												<div class="d-flex">
													<span
														class="text-center my-auto task-overview-task-name"
														@click="
															() => {
																if (editMode) {
																	editWidgets(groupIdx, taskIdx);
																} else {
																	previewTask(task);
																}
															}
														"
														>{{ task.name }}</span
													>
												</div>
												<a
													class="d-flex cursor-pointer"
													id="dropdownMenuLink"
													data-bs-toggle="dropdown"
													data-bs-auto-close="true"
													aria-expanded="false"
													style="color: white;"
												>
													<i class="fas fa-ellipsis-v my-auto"></i>
												</a>
												<ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
													<li v-if="editMode">
														<a
															class="dropdown-item"
															@click="
																() => {
																	tdx = taskIdx;
																	gdx = groupIdx;
																	editTask = clone(task);
																	editTask.groupIdx = groupIdx;
																	taskCreateModalVariant = 'edit-task';
																	$bvModal.show('showEditModal');
																}
															"
														>
															<i class="fas fa-edit"></i>
															Edit Task Details
														</a>
													</li>
													<li v-if="editMode">
														<template v-if="!task.autocomplete">
															<div class="dropdown-item" @click="editWidgets(groupIdx, taskIdx)">
																<i class="fas fa-edit"></i>
																Edit Widgets
															</div>
														</template>
													</li>
													<li v-if="!editMode">
														<div
															class="dropdown-item"
															@click="
																() => {
																	tdx = taskIdx;
																	gdx = groupIdx;
																	editTask = task;
																	task.groupIdx = groupIdx;
																	taskCreateModalVariant = 'preview-task';
																}
															"
														>
															<i class="fas fa-eye"></i>
															Preview Task Details
														</div>
													</li>
													<li>
														<div class="dropdown-item" @click="previewTask(task)">
															<i class="fas fa-eye"></i>
															Preview Task
														</div>
													</li>
													<li v-if="editMode">
														<a
															class="dropdown-item"
															@click="
																() => {
																	tdx = taskIdx;
																	gdx = groupIdx;
																	$bvModal.show('confirmTaskRemove');
																}
															"
														>
															<i class="fas fa-trash"></i>
															Remove Task
														</a>
													</li>
												</ul>
											</div>
											<template v-if="task.widgetMeta">
												<div
													v-for="(widget, widgetIndex) in task.widgetMeta"
													:key="`widget-${widget.id}-${widgetIndex}`"
													tag="div"
													class="task-preview-scoped"
													@click="
														() => {
															if (editMode) {
																editWidgets(groupIdx, taskIdx, widgetIndex);
															} else {
																previewTask(task);
															}
														}
													"
												>
													<i :class="getWidgetIcons[widget.widget.type]" :title="widget.widget.type"></i>
													<span :title="widget.widget.title"> {{ widget.widget.title }}</span>
												</div>
											</template>
										</b-card>
										<template v-if="task.initiateTimeline && task.initiateTimeline.timelines[1]">
											<b-card class="nested-task-card-scoped">
												<div class="d-flex">
													<span class="m-auto">{{ getIntiateTimelineTitleDisplay(task.initiateTimeline) }}</span>
												</div>
												<div
													v-for="(timeline, i) in Object.values(task.initiateTimeline.timelines).reverse()"
													:key="i"
													class="w-100 d-flex flex-column"
												>
													<template
														v-if="
															i === 0 &&
																task.initiateTimeline.offsetMetaCondition.key &&
																task.initiateTimeline.offsetMetaCondition.value
														"
													>
														<div class="w-100 d-flex">
															<b>
																If
																{{ task.initiateTimeline.offsetMetaCondition.key }}
																is {{ task.initiateTimeline.offsetMetaCondition.value }}:
															</b>
														</div>
													</template>
													<template
														v-if="
															i === 1 &&
																task.initiateTimeline.offsetMetaCondition.key &&
																task.initiateTimeline.offsetMetaCondition.value
														"
													>
														<div class="w-100 d-flex">
															<b>
																Else:
															</b>
														</div>
													</template>
													<div v-if="workflowMap[timeline]" class="text-left">
														- Initiate
														<router-link
															target="_blank"
															:to="{
																name: 'ClientWorkflowEditNew',
																params: {
																	uuid: workflowMap[timeline].workflow_uuid,
																	version: workflowMap[timeline].workflow_version
																}
															}"
														>
															{{ workflowMap[timeline].workflow_name }}
														</router-link>
													</div>
													<div class="text-left" v-else-if="
														task.initiateTimeline &&
														task.initiateTimeline.offsetMetaCondition.key &&
														task.initiateTimeline.offsetMetaCondition.value">
														Do nothing
													</div>
												</div>
											</b-card>
										</template>
									</div>
								</draggable>
							</template>
							<draggable
								class="col"
								group="taskgroup"
								v-model="groupFillers[groups.length]"
								@change="
									(e) => {
										onDragEndGroupFiller(e, groups.length);
									}
								"
							>
							</draggable>
						</div>
						<b-card v-if="editMode" class="add-task-card">
							<a class="add-task-card-container justify-content-center" @click="taskCreateModalVariant = 'add-task'">
								<i class="fas fa-plus-circle mt-5"></i>
								<p class="text-center">Add New Task</p>
							</a>
						</b-card>
					</div>
				</div>
			</div>
		</div>
		<TaskPreviewModal
			v-if="previewModalTask"
			:task="previewModalTask"
			:show="showPreviewModal"
			:onClose="() => (showPreviewModal = false)"
		></TaskPreviewModal>
		<TaskEditModal
			v-if="editMode && editWidgetsTask"
			:taskData="editWidgetsTask"
			:showModal="showWidgetsModal"
			:onClose="() => (showWidgetsModal = false)"
			:setupMeta="setupMeta"
		/>
	</ContentContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep'
import TaskCreateModal from './TaskCreateModal';
import TaskPreviewModal from './TaskPreviewModal';
import draggable from 'vuedraggable';
import moment from 'moment';
import TimelineView from '../TimelineView';
import TaskEditModal from './TaskEditModal';
import HelpModal from '@/components/shared/HelpModal';
import ContentContainer from '@/components/templates/ContentContainer';

export default {
	components: {
		draggable,
		TaskCreateModal,
		TimelineView,
		TaskPreviewModal,
		HelpModal,
		ContentContainer,
		TaskEditModal
	},
	data() {
		return {
			//workflow: "",
			groups: [],
			groupFillers: [],
			meta: null,
			tdx: null,
			gdx: null,
			loading: false,
			index: null,
			editTask: null,
			dragging: false,
			previewModalTask: null,
			editWidgetsTask: null,
			showPreviewModal: false,
			showWidgetsModal: false,
			taskCreateModalVariant: '',
			showTaskCreateModal: false,
			actionID: null,
			form: {
				consumerEmail: '',
				consumerMeta: {}
			},
			initializeTimelineConsumerOptions: [
				{ text: 'Use Existing Consumer', value: true },
				{ text: 'Create New Consumer', value: false }
			],
			initializeTimelineWithExistingConsumer: false,
			consumerMetaDisplayOrder: [],
			consumerMetaDates: [],
			consumers: [],
			consumerSelectionOptions: [],
			consumerSelected: null,
			newWorkflowTitle: ''
		};
	},
	computed: {
		...mapGetters([
			'getWorkflowInContext',
			'getWorkflowMeta',
			'getClientInContext',
			'getUserIsAdministrator',
			'getClientConsumerData',
			'getWidgetIcons',
			'getWorkflows'
		]),
		workflow() {
			return this.getWorkflowInContext;
		},
		workflowMap() {
			const map = {};
			this.getWorkflows.forEach((workflow) => {
				map[workflow.workflow_uuid] = workflow;
			});
			return map;
		},
		editMode() {
			if (!this.workflow) return false;

			return !this.workflow.workflow_finalized;
		}
	},
	async mounted() {
		await this.init();
	},
	methods: {
		async init() {
			this.loading = true;
			try {
				let forceLoadMeta = false;
				if (
					!this.getWorkflowInContext ||
					!(
						this.getWorkflowInContext?.workflow_uuid == this.$route?.params?.uuid &&
						this.getWorkflowInContext?.workflow_version == this.$route?.params?.version
					)
				) {
					let newRoute = null;
					if (
						!(await this.$store.dispatch('getWorkflow', {
							workflowUUID: this.$route?.params?.uuid,
							workflowVersion: this.$route?.params?.version
						}))
					) {
						await this.$store.dispatch('setWorkflowInContext', null);
						await this.$store.dispatch('setWorkflowMeta', null);
						newRoute = { name: 'WorkflowOverview' };
					}

					if (newRoute != null) {
						this.$router.push(newRoute).catch(() => {});
						return;
					}

					// load the meta IFF the workflow had to be loaded
					forceLoadMeta = true;
				}

				this.newWorkflowTitle = this.getWorkflowInContext?.workflow_name;

				if (forceLoadMeta || this.getWorkflowMeta?.workflow_id != this.workflow_id || !this.getWorkflowMeta?.meta?.groups) {
					// always load the meta
					if (
						(await this.$store.dispatch('getWorkflowMeta', {
							workflowID: this.workflow.workflow_id,
							clientID: this.workflow.client_id
						})) === false
					) {
						// meta is bad, so we need to bail
						await this.$store.dispatch('setWorkflowInContext', null);
						await this.$store.dispatch('setWorkflowMeta', null);
						this.$router
							.push({
								name: 'ClientWorkflowOverview',
								params: { clientID: this.getClientInContext.client_id }
							})
							.catch(() => {});
						return;
					}
				}

				if (this.getClientInContext && this.getClientInContext.consumer_meta) {
					let client = this.getClientInContext;
					let metaKeys = Object.keys(client.consumer_meta).sort(function(a, b) {
						return (client.consumer_meta[a].displayOrder || 0) - (client.consumer_meta[b].displayOrder || 0);
					});
					this.consumerMetaDisplayOrder = metaKeys;

					this.consumerMetaDates = Object.entries(client.consumer_meta)
						.filter(([_, meta]) => {
							return meta.type === 'date' || meta.type === 'date + time';
						})
						.map(([key, meta]) => {
							return {
								text: meta.name,
								value: key
							};
						});

					// store this as form models to bind
					this.resetForm();
				} else {
					this.consumerMetaDisplayOrder = [];
				}

				await this.$store.dispatch('getClientConsumers', {
					forceFetch: false,
					sort: undefined
				});
				this.consumers = this.getClientConsumerData;
				if (Array.isArray(this.consumers) && this.consumers.length > 0) {
					// set default
					this.initializeTimelineWithExistingConsumer = true;
					for (let i = 0; i < this.consumers.length; i++) {
						let consumerDisplay = (
							(this.consumers[i].meta['firstName'] || '') +
							' ' +
							(this.consumers[i].meta['lastName'] || '') +
							' <' +
							this.consumers[i].consumer_email +
							'>'
						).trim();
						this.consumerSelectionOptions.push({
							text: consumerDisplay,
							value: i
						});
					}

					this.meta = this.getWorkflowMeta.meta;

					await this.$bus.$emit('breadcrumbData', [
						{
							text: 'Workflows',
							to: {
								name: 'ClientWorkflowOverview'
							}
						},
						{
							text:
								(this.workflow.workflow_name.length > 50
									? this.workflow.workflow_name.substr(0, 50) + '...'
									: this.workflow.workflow_name) + ' Preview',
							to: {
								params: {
									uuid: this.$route.params.uuid,
									version: this.$route.params.version
								},
								name: 'ClientWorkflowPreview'
							}
						}
					]);

					// sort it - backwards
					this.consumerSelectionOptions.sort((a, b) => {
						return a.text.localeCompare(b.text, undefined, {
							numeric: true,
							sensitivity: 'base'
						});
					});
					// this is faster than an unshift operation by a mile.
					this.consumerSelectionOptions.push({
						text: 'Select an existing consumer...',
						value: null
					});
					this.consumerSelectionOptions.reverse();
				}
				this.setupMeta();
			} finally {
				// assert that client users & notification templates are set
				this.loading = false;
			}
		},
		clone(value) {
			return cloneDeep(value);
		},
		async onTaskCreated(task) {
			let taskMeta = {
				name: task.title,
				created: moment().format('YYYY-MM-DD'),
				manager_uuid: task.manager_uuid,
				notificationType: task.autocomplete ? null : task.notification_type,
				autocomplete: task.autocomplete,
				ttl: task.ttl || null,
				ttl_modifier: task.ttl_modifier || null,
				notificationTemplateID: task.autocomplete ? null : task.notification_template_id,
				widgetMeta: {}
			};

			this.meta.groups.push({
				created: moment().format('YYYY-MM-DD'),
				tasks: []
			});
			const groupIndex = this.meta.groups.length - 1;
			this.meta.groups[groupIndex].tasks.push(taskMeta);

			const newTask = {
				workflowID: this.workflow.workflow_id,
				workflowUUID: this.workflow.workflow_uuid,
				workflowVersion: this.workflow.workflow_version,
				clientID: this.getClientInContext.client_id,
				workflowMeta: this.meta
			};

			if (await this.$store.dispatch('setWorkflowMeta', newTask)) {
				this.setupMeta();
				if (!task.autocomplete) {
					this.$root.$emit('bv::toggle::collapse', 'add-task');
					const taskIndex = this.groups[groupIndex].tasks.length - 1;
					this.setTask(groupIndex, taskIndex);
				}
			}

			this.taskCreateModalVariant = '';
		},
		async onTaskUpdated(taskUpdatePayload) {
			this.loading = true;
			try {
				//TODO: UPDATE TO BE MORE DYNAMIC
				// In all honesty, taskcreatefrom needs to be split into 2 components and this needs to be done properly.
				this.meta.groups[this.gdx].tasks[this.tdx].name = taskUpdatePayload.title;
				this.meta.groups[this.gdx].tasks[this.tdx].manager_uuid = taskUpdatePayload.manager_uuid;
				this.meta.groups[this.gdx].tasks[this.tdx].notificationType = taskUpdatePayload.notification_type;
				this.meta.groups[this.gdx].tasks[this.tdx].autocomplete = taskUpdatePayload.autocomplete;
				this.meta.groups[this.gdx].tasks[this.tdx].ttl = taskUpdatePayload.ttl || null;
				this.meta.groups[this.gdx].tasks[this.tdx].notificationTemplateID = taskUpdatePayload.notification_template_id;
				this.meta.groups[this.gdx].tasks[this.tdx].initiateTimeline = taskUpdatePayload.initiateTimeline;
				this.meta.groups[this.gdx].tasks[this.tdx].delay = taskUpdatePayload.delay;
				
				if (
					!this.meta.groups[this.gdx].tasks[this.tdx].autocomplete &&
					(!taskUpdatePayload.notification_type || taskUpdatePayload.notification_type == 'NONE')
				) {
					this.meta.groups[this.gdx].tasks[this.tdx].widgetMeta = {};
				}

				const updatedMetaPayload = {
					workflowID: this.workflow.workflow_id,
					workflowUUID: this.workflow.workflow_uuid,
					workflowVersion: this.workflow.workflow_version,
					clientID: this.getClientInContext.client_id,
					workflowMeta: this.meta
				};

				await this.$store.dispatch('setWorkflowMeta', updatedMetaPayload);

				this.$root.$emit('bv::toggle::collapse', 'edit-task-' + this.gdx + '-' + this.tdx);
				[this.gdx, this.tdx, this.editTask] = [null, null, null];
			} finally {
				this.loading = false;
				this.taskCreateModalVariant = '';
			}
		},
		setTask(groupIdx, taskIdx) {
			sessionStorage.setItem('groupIdx', groupIdx);
			sessionStorage.setItem('taskIdx', taskIdx);
			this.init();
		},
		async removeTask(tdx, gdx) {
			if (this.meta.groups[gdx].tasks.length <= 1) {
				this.meta.groups.splice(gdx, 1);
			} else if (this.meta.groups.length <= 1 && this.meta.groups[gdx].tasks.length <= 1) {
				this.meta.groups = [];
			} else {
				this.meta.groups[gdx].tasks.splice(tdx, 1);
			}
			const updateMeta = {
				workflowID: this.workflow.workflow_id,
				workflowUUID: this.workflow.workflow_uuid,
				workflowVersion: this.workflow.workflow_version,
				clientID: this.getClientInContext.client_id,
				workflowMeta: this.meta
			};
			await this.$store.dispatch('setWorkflowMeta', updateMeta);
			this.setupMeta();
		},
		previewTask(task) {
			this.previewModalTask = task;
			this.showPreviewModal = true;
		},
		editWidgets(groupIdx, taskIdx) {
			this.editWidgetsTask = {
				groupIdx,
				taskIdx
			};
			this.showWidgetsModal = true;
		},
		setupMeta() {
			this.groups = [];
			this.groupFillers = [[]];
			if (this.getWorkflowMeta.meta) {
				this.meta = this.getWorkflowMeta.meta;
				if (Array.isArray(this.meta.groups)) {
					if (this.meta.groups.length > 0) {
						for (let i in this.meta.groups) {
							this.groups.push(this.meta.groups[i]);
							this.groupFillers.push([]);
						}
					}
				}
			} else {
				this.meta = { groups: [] };
			}

			this.$bus.$emit('updateGroups', this.groups);
		},
		async onDragEnd(event) {
			for (let i in this.meta.groups) {
				if (this.meta.groups[i] && (!Array.isArray(this.meta.groups[i].tasks) || this.meta.groups[i].tasks.length == 0)) {
					this.meta.groups.splice(i, 1);
				}
			}

			// no delay for first tasks in timeline
			this.meta.groups[0].tasks.forEach((task) => {
				task.delay = null;
			});

			const updateMeta = {
				workflowID: this.workflow.workflow_id,
				workflowUUID: this.workflow.workflow_uuid,
				workflowVersion: this.workflow.workflow_version,
				clientID: this.getClientInContext.client_id,
				workflowMeta: this.meta
			};

			// update the meta
			await this.$store.dispatch('setWorkflowMeta', updateMeta);
			this.setupMeta();
		},
		async onDragEndGroupFiller(event, index) {
			// slice in the value to wherever.
			this.meta.groups = [
				...this.groups.slice(0, index),
				{
					created: moment().format('YYYY-MM-DD'),
					tasks: this.groupFillers[index].splice(0, 1)
				},
				...this.groups.slice(index)
			];

			this.groups = this.meta.groups;
		},
		async finalizeWorkflow(workflow) {
			this.loading = true;
			try {
				const meta = {
					workflowID: workflow.workflow_id,
					clientID: this.getClientInContext.client_id
				};
				let result = await this.$store.dispatch('finalizeWorkflow', meta);

				if (!result) {
					return this.$store.dispatch(
						'createErrors',
						'There was an error finalizing this workflow, please verify that the workflow has both tasks and widgets then try again.'
					);
				} else {
					this.$store.dispatch('createAlerts', 'Workflow has been finalized!');
					this.workflow.workflow_finalized = true;
				}
			} catch (e) {
				return this.$store.dispatch('createErrors', e.message || 'Error finalizing workflow, please try again');
			} finally {
				this.loading = false;
			}
		},
		async handleNewVersion() {
			this.loading = true;
			try {
				await this.$store.dispatch('getWorkflowLatest', this.workflow);
				if (this.workflow.workflowLatestVersion) {
					// if this isn't set, an error occured. if an error occured, do nothing.
					this.$router
						.push({
							params: {
								uuid: this.workflow.workflowLatestVersion.workflow_uuid,
								version: this.workflow.workflowLatestVersion.workflow_version
							},
							name: 'ClientWorkflowEditNew'
						})
						.catch(() => {});
					if (this.workflow.latest_version != this.workflow.workflowLatestVersion.workflow_version) {
						this.$store.dispatch('createAlerts', 'New workflow version has been created');
					}
					this.$bus.$emit('closePreview', true);
				}
			} catch (e) {
				return this.$store.dispatch('createErrors', e.message || 'Error resolving new workflow version, please try again');
			} finally {
				this.loading = false;
			}
		},
		async editWorkflowTitle() {
			if (!this.newWorkflowTitle) return this.$store.dispatch('createErrors', 'Workflow name cannot be empty');

			if (this.newWorkflowTitle === this.workflow.workflow_name) {
				return this.$store.dispatch('createErrors', 'Workflow name cannot be the same as the current name');
			}

			this.loading = true;
			try {
				const workflowPayload = {
					clientID: this.workflow.client_id,
					workflowID: this.workflow.workflow_id,
					workflowUUID: this.workflow.workflow_uuid,
					workflowVersion: this.workflow.workflow_version,
					workflowName: this.newWorkflowTitle,
					workflowTimelineAftermathUUID: this.workflow.workflow_timeline_aftermath_uuid
				};

				let result = await this.$store.dispatch('updateWorkflow', workflowPayload);
				if (!result) {
					return this.$store.dispatch('createErrors', 'There was an error updating the workflow title, please try again.');
				} else {
					this.$store.dispatch('createAlerts', 'Workflow title has been updated!');

					this.$bvModal.hide('editWorkflowTitle');
					this.$store.commit('setWorkflowInContext', result);

					await this.$bus.$emit('breadcrumbData', [
						{
							text: 'Workflows',
							to: {
								name: 'ClientWorkflowOverview'
							}
						},
						{
							text:
								(this.workflow.workflow_name.length > 50
									? this.workflow.workflow_name.substr(0, 50) + '...'
									: this.workflow.workflow_name) + ' Preview',
							to: {
								params: {
									uuid: this.$route.params.uuid,
									version: this.$route.params.version
								},
								name: 'ClientWorkflowPreview'
							}
						}
					]);
				}
			} catch (e) {
				return this.$store.dispatch('createErrors', e.message || 'Error updating workflow title, please try again');
			} finally {
				this.loading = false;
			}
		},
		resetForm() {
			this.consumerSelected = null;
			this.form.consumerEmail = null;
			if (
				this.getClientInContext.consumer_meta &&
				Array.isArray(this.consumerMetaDisplayOrder) &&
				this.consumerMetaDisplayOrder.length > 0
			) {
				for (let i in this.consumerMetaDisplayOrder) {
					this.form.consumerMeta[this.consumerMetaDisplayOrder[i]] = {
						value: '',
						timeValue: null
					};
				}
			}
		},
		initializeTimelineSetConsumer() {
			if (this.consumerSelected === null || isNaN(this.consumerSelected)) {
				return this.resetForm();
			}
			this.form.consumerEmail = this.consumers[this.consumerSelected].consumer_email;
			if (
				this.getClientInContext.consumer_meta &&
				Array.isArray(this.consumerMetaDisplayOrder) &&
				this.consumerMetaDisplayOrder.length > 0
			) {
				for (let i in this.consumerMetaDisplayOrder) {
					if (this.consumers[this.consumerSelected]?.meta[this.consumerMetaDisplayOrder[i]]) {
						if (
							this.getClientInContext.consumer_meta[this.consumerMetaDisplayOrder[i]].type == 'date' ||
							this.getClientInContext.consumer_meta[this.consumerMetaDisplayOrder[i]].type == 'date + time'
						) {
							let date = new Date(this.consumers[this.consumerSelected]?.meta[this.consumerMetaDisplayOrder[i]] || null);
							if (date != 'Invalid Date' && date !== null) {
								this.form.consumerMeta[this.consumerMetaDisplayOrder[i]] = {
									value: date.toISOString().substr(0, 10),
									timeValue:
										this.getClientInContext.consumer_meta[this.consumerMetaDisplayOrder[i]].type == 'date + time'
											? `${date
													.getHours()
													.toString()
													.padStart(2, '0')}:${date
													.getMinutes()
													.toString()
													.padStart(2, '0')}:${date
													.getSeconds()
													.toString()
													.padStart(2, '0')}`
											: null
								};
							} else {
								this.form.consumerMeta[this.consumerMetaDisplayOrder[i]] = {
									value: '',
									timeValue:
										this.getClientInContext.consumer_meta[this.consumerMetaDisplayOrder[i]].type == 'date + time' ? '' : null
								};
							}
						} else {
							this.form.consumerMeta[this.consumerMetaDisplayOrder[i]] = {
								value: this.consumers[this.consumerSelected]?.meta[this.consumerMetaDisplayOrder[i]],
								timeValue: null
							};
						}
					} else {
						this.form.consumerMeta[this.consumerMetaDisplayOrder[i]] = {
							value: '',
							timeValue: null
						};
					}
				}
			}
		},
		async createTimeline() {
			if (this.isSubmitting == (this.isSubmitting = true)) {
				return false;
			}
			// TODO: HELPER FUNCTION REALLOCATION
			let resolveValidDateTime = (function() {
				// Potential TODO: replace with client timezone when its added in, for now assumeoffsets from browser.
				let timezoneOffsetMin = new Date().getTimezoneOffset(),
					offsetStr = `${parseInt(Math.abs(timezoneOffsetMin / 60))
						.toString()
						.padStart(2, '0')}:${Math.abs(timezoneOffsetMin % 60)
						.toString()
						.padStart(2, '0')}`,
					timezoneStandard;

				// Add an opposite sign to the offset
				// If offset is 0, it means timezone is UTC
				if (timezoneOffsetMin < 0) {
					timezoneStandard = '+' + offsetStr;
				} else if (timezoneOffsetMin > 0) {
					timezoneStandard = '-' + offsetStr;
				} else if (timezoneOffsetMin == 0) {
					timezoneStandard = 'Z';
				}
				// resolve date with timezone offset built in
				return (date, timeString) => {
					let time = timeString;
					if (!time) {
						time = '00:00:00';
					} else if (time.length == 5) {
						time += ':00';
					} else if (time.length != 8) {
						return false;
					}
					let iso8601 = `${date}T${time}${timezoneStandard}`;
					if (new Date(iso8601) == 'Invalid Date') {
						return false;
					}
					return iso8601;
				};
			})();
			this.loading = true;
			let hasError = false;
			try {
				const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				this.$bvModal.hide('createNewTimeline');
				let errors = [];

				if (!this.form.consumerEmail || !re.test(this.form.consumerEmail)) {
					errors.push('Please add a valid e-mail.');
				}
				let consumerMeta = {};
				if (Array.isArray(this.consumerMetaDisplayOrder) && this.consumerMetaDisplayOrder.length > 0) {
					for (let i in this.consumerMetaDisplayOrder) {
						if (
							this.getClientInContext.consumer_meta[this.consumerMetaDisplayOrder[i]].required &&
							!this.form.consumerMeta[this.consumerMetaDisplayOrder[i]].value
						) {
							errors.push(`Please add a valid ${this.consumerMetaDisplayOrder[i]}`);
						} else if (this.form.consumerMeta[this.consumerMetaDisplayOrder[i]].value) {
							if (
								this.getClientInContext.consumer_meta[this.consumerMetaDisplayOrder[i]].type == 'date' ||
								this.getClientInContext.consumer_meta[this.consumerMetaDisplayOrder[i]].type == 'date + time'
							) {
								let dateVal = resolveValidDateTime(
									this.form.consumerMeta[this.consumerMetaDisplayOrder[i]].value,
									this.form.consumerMeta[this.consumerMetaDisplayOrder[i]].timeValue
								);
								if (!dateVal) {
									errors.push(`Invalid value provided for ${this.consumerMetaDisplayOrder[i]}`);
								}
								consumerMeta[this.consumerMetaDisplayOrder[i]] = dateVal;
							} else {
								//TODO Other validation here?
								consumerMeta[this.consumerMetaDisplayOrder[i]] = this.form.consumerMeta[this.consumerMetaDisplayOrder[i]].value;
							}
						}
					}
				}
				if (errors.length > 0) {
					hasError = true;
					return this.$store.dispatch('createErrors', errors);
				}

				let timeline = {
					clientID: this.getClientInContext.client_id,
					workflowUUID: this.workflow.workflow_uuid,
					consumerEmail: this.form.consumerEmail,
					consumerMeta: consumerMeta
				};
				this.resetForm();

				// this aught to be the created timeline ID
				let createdTimelineResult = await this.$store.dispatch('initializeTimeline', timeline);

				if (typeof createdTimelineResult == 'object' && createdTimelineResult.errors) {
					hasError = true;
					return this.$store.dispatch('createErrors', createdTimelineResult.errors);
				} else if (!createdTimelineResult || isNaN(createdTimelineResult)) {
					hasError = true;
					return this.$store.dispatch('createErrors', 'Failed to create timeline, please try again');
				}

				this.$store.dispatch('createAlerts', 'Timeline has successfully been created');
				await this.$router
					.push({
						name: 'ClientTimelineOverview',
						params: {
							timelineID: createdTimelineResult
						}
					})
					.catch(() => {});
			} catch (e) {
				hasError = true;
				return this.$store.dispatch('createErrors', e.message);
			} finally {
				if (hasError) {
					this.$bvModal.show('createNewTimeline');
				} else {
					this.resetForm();
				}
				this.loading = false;
				this.isSubmitting = false;
			}
		},
		getIntiateTimelineTitleDisplay(initiateTimeline) {
			try {
				const modifiers = {
					86400: 'Day',
					604800: 'Week',
					31536000: 'Year'
				};

				let modifier = 0;
				Object.keys(modifiers)
					.reverse()
					.forEach((m) => {
						if (modifier > 0) return;

						const modAmount = parseInt(m, 10);
						const amount = initiateTimeline.offsetModifierAmount;

						if (amount >= modAmount) {
							modifier = modAmount;
						}
					});

				const modifierCount = !modifier ? '' : Math.floor(initiateTimeline.offsetModifierAmount / modifier);

				const relativeDisplay = modifiers[modifier]
					? `${modifierCount} ${modifiers[modifier]}${modifierCount === 1 ? '' : 's'}`
					: 'Immediately';
				const directionDisplay = initiateTimeline.offsetModifierDirection === 1 ? 'After' : 'Before';

				let metaDisplay = 'Timeline End';
				const meta = this.consumerMetaDates.find((m) => m.value === initiateTimeline.offsetMeta);

				if (meta) {
					metaDisplay = meta.text;
				}

				return `${relativeDisplay} ${directionDisplay} ${metaDisplay}`;
			} catch (err) {
				return '';
			}
		}
	},
	watch: {
		$route(to, from) {
			if (to.params?.version !== from.params?.verson || to.params?.uuid !== from.params?.uuid) {
				this.init();
			}
		}
	}
};
</script>

<style scoped>
.task-overview-task-name:hover {
	cursor: pointer;
	color: var(--light-gray-color);
}

.task-preview-scoped {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block !important;
	width: 100%;
	padding-top: 8px;
	background-color: white;
}

.task-preview-scoped:hover {
	background-color: #f5f5f5;
	cursor: pointer;
}

#dropdownMenuLink {
	display: flex;
	justify-content: flex-end;
	width: 32px;
	padding-right: 1rem;
}

.timeline-container-scoped {
	background: var(--content-container-bg);
	z-index: 2;
}

.task-card-scoped {
	margin: 5px 15px 0px 0px;
	min-height: 200px;
	width: 250px;
	max-width: 300px;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.nested-task-card-scoped {
	margin: 5px 15px 0px 12px;
	min-height: 200px;
	width: 250px;
	max-width: 300px;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.task-card-scoped > .card-body > div,
.nested-task-card-scoped > .card-body > div {
	padding-left: 1rem;
	min-height: 40px;
	margin-top: auto;
	margin-bottom: auto;
}

.task-card-scoped > .card-body > div:nth-child(1),
.nested-task-card-scoped > .card-body > div:nth-child(1) {
	background: var(--btn-blue-background);
	color: white;
	font-weight: bold;
}

.task-card-scoped > .card-body > div:nth-child(n + 2),
.nested-task-card-scoped > .card-body > div:nth-child(n + 2) {
	display: grid;
	grid-template-columns: 40px 1fr;
	padding-right: 1rem;
}

.task-card-scoped > .card-body > div:nth-child(n + 2) > *,
.nested-task-card-scoped > .card-body > div:nth-child(n + 2) > * {
	margin-top: auto;
	margin-bottom: auto;
}

div.dragging-card > .task-card-scoped > .card-body > div:nth-child(1)::after,
div.dragging-card > .nested-task-card-scoped > .card-body > div:nth-child(1)::after {
	content: none;
	top: 0 !important;
}

div:not(.sortable-chosen) > .task-card-scoped > .card-body > div:nth-child(1)::after {
	content: '';
	position: absolute;
	top: calc(-100vh + 20px);
	left: -12px;
	width: 12px;
	height: 100vh;
	border-bottom: 4px solid;
	border-left: 4px solid;
	border-color: var(--btn-blue-background);
}

div:not(.sortable-chosen) > .nested-task-card-scoped > .card-body > div:nth-child(1)::after {
	content: '';
	position: absolute;
	left: -12px;
	width: 12px;
	top: -7px;
	height: 28px;
	border-bottom: 4px solid;
	border-left: 4px solid;
	border-color: var(--btn-blue-background);
}

.task-card-scoped > .card-body,
.nested-task-card-scoped > .card-body {
	padding: 0;
}

.edit-title-button {
	color: var(--btn-blue-background);
	cursor: pointer;
}

.edit-title-button:hover {
	color: var(--btn-blue-hover-background);
}

.edit-title-button:active {
	color: black;
}
</style>
